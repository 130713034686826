import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { findTourById } from 'broker-admin/store/dispatch-details/utils/find-tour-by-id';
import { getDateFromISO } from 'common/utils/time';
import { checkIsPickupDeliveryWaypoint } from 'broker-admin/store/dispatch-details/utils/check-is-pickup-delivery-waypoint';

export const findFirstPickupDeliveryWaypointDate = (
    dispatchDetails: DispatchDetailsT | null,
    tourId: TourIdT | null,
): string | null => {
    const tour = findTourById(dispatchDetails, tourId);
    const waypoints = tour?.waypoints || [];

    const firstPickupDeliveryWaypoint = waypoints.find(checkIsPickupDeliveryWaypoint) || null;

    const dateTimeFrom =
        firstPickupDeliveryWaypoint?.correctedDateTimeFrom || firstPickupDeliveryWaypoint?.originalDateTimeFrom || null;
    if (!dateTimeFrom) {
        return null;
    }

    return getDateFromISO(dateTimeFrom);
};
