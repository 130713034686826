import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { findTourById } from 'broker-admin/store/dispatch-details/utils/find-tour-by-id';
import { checkIsPickupDeliveryWaypoint } from 'broker-admin/store/dispatch-details/utils/check-is-pickup-delivery-waypoint';

export const getPickupDeliveryWaypoints = (
    dispatchDetails: DispatchDetailsT | null | undefined,
    tourId: TourIdT | null,
) => {
    const actualTour = findTourById(dispatchDetails, tourId);
    return actualTour?.waypoints?.filter(checkIsPickupDeliveryWaypoint) || [];
};
