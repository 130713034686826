import { findTourById } from 'broker-admin/store/dispatch-details/utils/find-tour-by-id';
import isNumber from 'lodash/isNumber';
import { formatLocation } from 'common/utils/formatters';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { InitDropPointT } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/models';
import { checkIsPickupDeliveryWaypoint } from 'broker-admin/store/dispatch-details/utils/check-is-pickup-delivery-waypoint';

export const createInitPayloadDropPoint = (
    dispatchDetails: DispatchDetailsT | null,
    tourId: TourIdT | null,
): InitDropPointT | null => {
    const tour = findTourById(dispatchDetails, tourId);
    const waypoints = tour?.waypoints || [];

    const payloadWaypoints = waypoints?.filter(checkIsPickupDeliveryWaypoint) || [];
    const lastPayloadWaypoint = payloadWaypoints[payloadWaypoints.length - 1] || null;

    const waypoint = lastPayloadWaypoint || null;
    if (!waypoint) {
        return null;
    }

    const waypointAddress = waypoint?.address || null;
    if (!waypointAddress) {
        return null;
    }

    if (!isNumber(waypointAddress?.latitude) || !isNumber(waypointAddress?.longitude)) {
        return null;
    }

    return {
        location: {
            address: formatLocation(waypointAddress),
            utcOffsetMinutes: null,
            addressComponents: {
                countryCode: waypointAddress.country,
                street1: waypointAddress?.street1,
                street2: waypointAddress?.street2,
                zipCode: waypointAddress?.zipCode,
                city: waypointAddress?.city,
            },
            point: {
                lat: waypointAddress.latitude || 0,
                lng: waypointAddress.longitude || 0,
            },
        },
        index: isNumber(waypoint?.index) ? waypoint?.index : null,
    };
};
